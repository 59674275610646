<!--  -->
<template>
    <div class="model-list-box">
        <div class="model clearfix">
            <div class="new-model model-item" @click="onNewModel">
                <div class="new">+新建模型</div>
            </div>
            <div class="model-item" v-for="item in modelList">
                <div class="model-title" :id="item.id" @click="onEditModel(item.id)">
                    <img class="model-title-img" src="../assets/images/model.png" alt="" />
                    <div class="model-details">
                        <h4 class="model-name">{{ item.model_name }}</h4>
                        <div class="model-text">{{ item.model_memo }}</div>
                        <div class="model-text">{{ utils.formatDate(item.updated_date) }}</div>
                        <div class="del">
                            <i class="el-icon-delete" @click.stop="onDel(item.id)"></i>
                        </div>
                        <!-- <div class="model-date">{{item.updated_date}}}</div> -->
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <span>确认删除？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onDelModal()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { formatDate } from '../util/util'
import baseUrl from '../util/config'
export default {
    data() {
        return {
            modelList: '',
            dialogVisible: false,
            id: 0,
        }
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() { },
    //生命周期 - 挂载完成（访问DOM元素）,函数调用
    mounted() {
        this.getMoldelList()
    },
    //计算属性
    computed: {},
    //函数
    methods: {
        //模型列表
        getMoldelList() {
            this.$axios({
                method: 'get',
                url: baseUrl + '/models/list',
                headers: {
                    'Content-type': 'application/json; charset=utf-8'
                }
            }).then(
                res => {
                    console.log(res, 'res')
                    this.modelList = res.data.resultData
                },
                err => { }
            )
        },
        //删除模型
        onDel(ev) {
            this.id = ev;
            this.dialogVisible = true;
        },
        onDelModal() {

            this.$axios({
                method: 'get',
                url: baseUrl + '/models/delete?id=' + this.id + '',
                headers: {
                    'Content-type': 'application/json; charset=utf-8'
                }
            }).then(
                res => {
                    this.getMoldelList()
                    this.dialogVisible = false;
                },
                err => { }
            )
        },
        //新建模型
        onNewModel() {
            let routeUrl = this.$router.resolve({
                path: '/ef'
            })
            window.open(routeUrl.href, '_blank')
        },
        //编辑模型
        onEditModel(ev) {
            let routeUrl = this.$router.resolve({
                path: '/ef',
                query: { id: ev }
            })
            window.open(routeUrl.href, '_blank')
        }
    },
    //监控
    watch: {}
}
</script>
<style scoped>
.model-list-box {
    margin-bottom: 50px;
}

.model {
    /* display:felx; */
    text-align: left;
    margin-bottom: 60px;
}

.model-item {
    box-sizing: border-box;
    float: left;
    width: 16.6%;
    height: 225px;
    padding: 0 10px;
    cursor: pointer;
    margin-top: 20px;
}

.new {
    cursor: pointer;
    vertical-align: top;
    width: 100%;
    height: 100%;
    color: #45b6f7;
    border: 2px dashed #45b6f7;
    font-size: 20px;
    text-align: center;
    line-height: 225px;
    transition: all 0.3s ease;
}

.model-title {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    border: 1px solid #45b6f7;
}

.model-title-img {
    width: 100%;
    height: 96px;
}

.model-details {
    padding: 0 4px;
}

.model-name {
    margin-top: 8px;
    font-size: 14px;
}

.model-text {
    margin-top: 8px;
    line-height: 26px;
}

.model-date {
    text-align: right;
}

.del {
    text-align: right;
    margin-right: 4px;
}
</style>
  


